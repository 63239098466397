<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <v-layout class="d-block customer-create-new-customer">
        <v-row>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="12">
                    <table width="100%" style="table-layout: fixed">
                      <tr v-if="false">
                        <td width="20%">
                          <label class="font-size-16 font-weight-500"
                            >Customer Type
                          </label>
                        </td>
                        <td colspan="3">
                          <v-radio-group
                            v-model="detail.company_type"
                            row
                            hide-details
                            class="mt-0"
                          >
                            <v-radio
                              style="margin-right: -2px"
                              value="company"
                              color="blue"
                            ></v-radio>
                            <v-chip
                              label
                              color="blue"
                              outlined
                              class="text-white p-3 mr-5"
                              small
                              >Company</v-chip
                            >
                            <v-radio
                              value="personal"
                              color="red"
                              style="margin-right: -2px"
                            ></v-radio>
                            <v-chip
                              label
                              color="red"
                              outlined
                              class="text-white p-3"
                              small
                              >Individual</v-chip
                            >
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr>
                        <td width="20%">
                          <label class="font-size-16 font-weight-500 required"
                            >Primary Contact</label
                          >
                        </td>
                        <td colspan="3">
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              flex-direction: row;
                              flex-wrap: nowrap;
                            "
                          >
                            <v-select
                              id="salutation"
                              :items="salutationList"
                              dense
                              filled
                              item-text="text"
                              item-value="value"
                              item-color="cyan"
                              placeholder="Salutation"
                              v-model="detail.salutation"
                              solo
                              flat
                              style="min-width: unset"
                              color="cyan"
                            />

                            <v-text-field
                              v-model="detail.first_name"
                              id="first_name"
                              :rules="[
                                validateRules.required(
                                  detail.first_name,
                                  'first name'
                                ),
                                validateRules.minLength(
                                  detail.first_name,
                                  'first name',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.first_name,
                                  'first name',
                                  100
                                ),
                              ]"
                              dense
                              filled
                              placeholder="First Name"
                              solo
                              flat
                              color="cyan"
                              style="min-width: 40%"
                              class="required-field mx-4"
                              v-on:keyup="appendDisplayName()"
                            />

                            <v-text-field
                              v-model="detail.last_name"
                              :rules="[
                                validateRules.minLength(
                                  detail.last_name,
                                  'last name',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.last_name,
                                  'last name',
                                  100
                                ),
                              ]"
                              id="last_name"
                              dense
                              filled
                              placeholder="Last Name"
                              solo
                              flat
                              style="min-width: 40%"
                              color="cyan"
                              v-on:keyup="appendDisplayName()"
                            />
                          </div>
                        </td>
                      </tr>
                      <template v-if="detail.company_type == 'company'">
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 required"
                              >Company Name</label
                            >
                          </td>
                          <td>
                            <TextValidateInput
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              :rules="company_rules()"
                              id="company-name"
                              placeholder="Company Name"
                              :validationField="{
                                url_type: 'customer',
                                filter_type: 'contact',
                                field: 'company_name',
                              }"
                              :parent-id="detail.id"
                              :current-id="detail.id"
                              show-dropdown
                              hide-top-margin
                              v-model="detail.company_name"
                            />
                          </td>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 pl-20"
                              >Reference</label
                            >
                          </td>
                          <td width="30%">
                            <v-text-field
                              v-model="detail.reference"
                              id="reference"
                              dense
                              filled
                              placeholder="Reference"
                              solo
                              flat
                              color="cyan"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 required"
                              >Display Name</label
                            >
                          </td>
                          <td width="30%">
                            <TextValidateInput
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              :rules="[
                                validateRules.required(
                                  detail.display_name,
                                  'Display Name'
                                ),
                              ]"
                              id="display-name"
                              placeholder="Display Name"
                              :validationField="{
                                url_type: 'customer',
                                filter_type: 'contact',
                                field: 'display_name',
                              }"
                              :parent-id="detail.id"
                              :current-id="detail.id"
                              show-dropdown
                              hide-top-margin
                              v-model="detail.display_name"
                            />
                          </td>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 pl-20"
                              >UEN</label
                            >
                          </td>
                          <td width="30%">
                            <TextValidateInput
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              :rules="[
                                validateRules.minLength(detail.uen, 'UEN', 3),
                                validateRules.maxLength(detail.uen, 'UEN', 50),
                              ]"
                              id="display-name"
                              placeholder="UEN"
                              :validationField="{
                                url_type: 'customer',
                                filter_type: 'contact',
                                field: 'uen',
                              }"
                              :parent-id="detail.id"
                              :current-id="detail.id"
                              show-dropdown
                              hide-top-margin
                              v-model="detail.uen"
                            />
                          </td>
                        </tr>
                      </template>
                      <template v-if="detail.company_type == 'personal'">
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 required"
                              >Display Name</label
                            >
                          </td>
                          <td colspan="3">
                            <TextValidateInput
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              :rules="[
                                validateRules.required(
                                  detail.display_name,
                                  'display name'
                                ),
                                validateRules.minLength(
                                  detail.display_name,
                                  'display name',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.display_name,
                                  'display name',
                                  100
                                ),
                              ]"
                              id="display-name"
                              placeholder="Display Name"
                              :validationField="{
                                url_type: 'customer',
                                filter_type: 'contact_person',
                                field: 'display_name',
                              }"
                              :parent-id="detail.id"
                              :current-id="detail.id"
                              show-dropdown
                              hide-top-margin
                              v-model="detail.display_name"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500"
                              >Email Address</label
                            >
                          </td>
                          <td colspan="3">
                            <v-text-field
                              v-model="detail.person_email"
                              :rules="[
                                validateRules.validEmail(
                                  detail.person_email,
                                  'email'
                                ),
                                validateRules.minLength(
                                  detail.person_email,
                                  'email',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.person_email,
                                  'email',
                                  100
                                ),
                              ]"
                              dense
                              filled
                              placeholder="Email"
                              solo
                              flat
                              color="cyan"
                              class="required-field"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500"
                              >Mobile Number</label
                            >
                          </td>
                          <td colspan="3">
                            <PhoneTextField
                              v-model="detail.mobile_number"
                              label="Mobile Number"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500"
                              >Landline Number</label
                            >
                          </td>
                          <td colspan="3">
                            <PhoneTextField
                              v-model="detail.landline_number"
                              label="Landline Number"
                            />
                          </td>
                        </tr>
                      </template>
                      <template v-if="detail.company_type == 'company'">
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 required"
                              >Company Email Address</label
                            >
                          </td>
                          <td width="30%">
                            <TextField
                              v-model="detail.company_email"
                              :rules="[
                                validateRules.required(
                                  detail.company_email,
                                  'email'
                                ),
                                validateRules.validEmail(
                                  detail.company_email,
                                  'email'
                                ),
                                validateRules.minLength(
                                  detail.company_email,
                                  'email',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.company_email,
                                  'email',
                                  30
                                ),
                              ]"
                              dense
                              filled
                              placeholder="Email"
                              solo
                              flat
                              color="cyan"
                              class="required-field"
                              :maxlength="250"
                              counter="30"
                            />
                            <!-- <v-text-field
                              v-model="detail.company_email"
                              :rules="[
                                validateRules.required(
                                  detail.company_email,
                                  'email'
                                ),
                                validateRules.validEmail(
                                  detail.company_email,
                                  'email'
                                ),
                                validateRules.minLength(
                                  detail.company_email,
                                  'email',
                                  2
                                ),
                                validateRules.maxLength(
                                  detail.company_email,
                                  'email',
                                  30
                                ),
                              ]"
                              dense
                              filled
                              placeholder="Email"
                              solo
                              flat
                              color="cyan"
                              counter="30"
                              class="required-field"
                            /> -->
                          </td>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 pl-20"
                              >Website</label
                            >
                          </td>
                          <td width="30%">
                            <TextField
                              id="website"
                              v-model="detail.website"
                              :rules="[
                                validateRules.minLength(
                                  detail.website,
                                  'website',
                                  3
                                ),
                                validateRules.maxLength(
                                  detail.website,
                                  'website',
                                  50
                                ),
                              ]"
                              dense
                              filled
                              placeholder="Website"
                              solo
                              flat
                              color="cyan"
                              class="required-field"
                              :maxlength="250"
                              counter="50"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 required"
                              >Company phone number</label
                            >
                          </td>
                          <td width="30%">
                            <PhoneTextField
                              required
                              v-model="detail.company_number"
                              label="Company phone number"
                            />
                          </td>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 pl-20"
                              >Company fax</label
                            >
                          </td>
                          <td width="30%">
                            <PhoneTextField
                              v-model="detail.company_fax"
                              label="Company fax"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500"
                              >Ship Code</label
                            >
                          </td>
                          <td width="30%">
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="shipcode"
                              :items="shipCodeList"
                              v-model="detail.shipcode"
                              placeholder="Ship Code"
                              solo
                              flat
                              item-color="cyan"
                              item-text="text"
                              item-value="text"
                              hide-details
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Ship Code Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </td>
                          <td width="20%">
                            <label class="font-size-16 font-weight-500 pl-20"
                              >Import Ref</label
                            >
                          </td>
                          <td width="30%">
                            <v-text-field
                              id="Import Ref"
                              v-model="detail.import_ref"
                              dense
                              filled
                              placeholder="Import Ref"
                              solo
                              flat
                              color="cyan"
                              class="required-field"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="20%" valign="top">
                            <label class="font-size-16 font-weight-500"
                              >Customer Remark</label
                            >
                          </td>
                          <td colspan="3">
                            <v-textarea
                              v-model="detail.customer_remark"
                              auto-grow
                              dense
                              filled
                              color="cyan"
                              placeholder="Customer Remark"
                              solo
                              flat
                              row-height="15"
                              v-on:paste="(e) => onPaste(e)"
                              v-on:keypress="(e) => manageLimit(e)"
                            />
                            <div class="text-right">
                              {{
                                detail.customer_remark
                                  ? detail.customer_remark.length
                                  : 0
                              }}/200
                            </div>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </v-col>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </v-col>
          <v-col
            class="iv-custom-field"
            cols="3"
            md="3"
            sm="3"
            v-on:mouseleave="imageTemplate = false"
            v-on:mouseover="imageTemplate = true"
          >
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto">
                <v-btn
                  class="my-4 custom-bold-button width-100"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <!--    <v-row>
            <v-col md="12" sm="12">
              <h3>Contact Details</h3>
              <ContactPersons
                
                  ></ContactPersons>
            </v-col>
        </v-row> -->
      </v-layout>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { GET, QUERY, DELETE } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { getConfig } from "@/core/services/local.service";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
/* import ContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue"; */
export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  props: {
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    lead: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupList: [],
      entityList: [],
      shipCodeList: [],
      proposal: 0,
      timeoutLimit: 500,
      timeout: null,
      autocompleteList: new Array(),
      autocompleteLoading: false,
      detail: {
        primary: true,
        company_email: null,
        company_number: null,
        company_fax: null,
        person_email: null,
        landline_number: null,
        mobile_number: null,
        salutation: "mr",
        reference: null,
        customer_remark: null,
        first_name: null,
        last_name: null,
        company_type: "company",
        company_name: null,
        uen: null,
        display_name: null,
        profile_logo: null,
        entity_id: 1,
        group_id: 1,
        phone: null,
        website: null,
        security_code: null,
        unit_number: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        country: null,
        state: null,
        city: null,
        details: [],
      },
      imageLoading: false,
      pageLoading: false,
      emailDetails: [
        {
          title: "main",
          value: null,
          type: 1,
          id: null,
        },
      ],
      contactDetails: [
        {
          title: "main",
          value: null,
          type: 2,
          id: null,
        },
        {
          title: "fax",
          value: null,
          type: 2,
          id: null,
        },
      ],
      imageTemplate: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    customer() {
      console.log(this.customer);
    },
    "detail.primary"() {
      if (this.detail.primary) {
        this.detail.display_name = this.detail.company_name;
      }
    },
    "detail.company_name"() {
      if (this.detail.primary) {
        //if(!this.detail.id){
        this.detail.display_name =
          this.detail.company_type == "company"
            ? this.detail.company_name
            : null;
        //}
      }
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveContactDetail", param);
      },
    },
    lead: {
      deep: true,
      immediate: true,
      handler() {
        this.getLead();
      },
    },
    emailDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
    contactDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    resetData() {
      (this.detail.salutation = "mr"),
        (this.detail.first_name = null),
        (this.detail.last_name = null),
        (this.detail.company_name = null),
        (this.detail.uen = null),
        (this.detail.display_name = null);

      this.detail.company_email = null;
      this.detail.website = null;
      this.detail.company_number = null;
      this.detail.company_fax = null;
      this.detail.customer_remark = null;
    },

    company_rules() {
      const rules = [];

      if (this.detail.primary) {
        rules.push(
          this.validateRules.required(this.detail.company_name, "display name")
        );
      }

      rules.push(
        this.validateRules.minLength(
          this.detail.company_name,
          "display name",
          2
        )
      );

      rules.push(
        this.validateRules.maxLength(
          this.detail.company_name,
          "display name",
          100
        )
      );

      return rules;
    },
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.detail;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      if (this.detail.company_type == "personal") {
        this.detail.display_name = result.join(" ");
      }

      /*    } */
    },
    clearAddress() {
      const _this = this;
      _this.detail.postal_code = null;
      _this.detail.address_line_1 = null;
      _this.detail.address_line_2 = null;
      _this.detail.unit_number = null;
    },
    manageLimit(e) {
      if (
        this.detail.customer_remark &&
        this.detail.customer_remark.length > 199
      ) {
        e.preventDefault();
      }
    },
    /*    onPaste(e){
         let coppied = e.clipboardData.getData("Text")
		 if(e && coppied && coppied.length > 199){
			this.detail.customer_remark = coppied.substring(0, 200)
			e.preventDefault();
		 } 
		}, */
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.detail.customer_remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.detail.customer_remark = trimValue;
        e.preventDefault();
      }
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    /* getExistingData(search_key,name) {
      this.suggestedItems = [];
      if (search_key) {
        let requestParams = {
          type: 'customers',
          name: name,
          value: search_key,
        };
        this.getCustomerList(requestParams);
      }
    }, */
    getShipCode() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "ship-code/setting",
        })
        .then(({ data }) => {
          _this.shipCodeList = data.shipcodes;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.detail.postal_code = result.zip_code;
              _this.detail.address_line_1 = result.street_1;
              _this.detail.address_line_2 = result.street_2;
              _this.detail.unit_number = result.unit_no;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.detail.postal_code && _this.detail.postal_code.length == 6) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.detail.postal_code },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        } else {
          _this.autocompleteList = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateDetails() {
      const _this = this;
      _this.detail.details = [];
      for (let i = 0; i < _this.emailDetails.length; i++) {
        if (_this.emailDetails[i]) {
          delete _this.emailDetails[i].default;
          _this.detail.details.push(_this.emailDetails[i]);
        }
      }
      for (let z = 0; z < _this.contactDetails.length; z++) {
        if (_this.contactDetails[z]) {
          delete _this.contactDetails[z].default;
          _this.detail.details.push(_this.contactDetails[z]);
        }
      }
    },
    pushEmailDetail() {
      this.emailDetails.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index, row) {
      if (row && row.id) {
        this.$store
          .dispatch(DELETE, {
            url: `customer/${row.customer}/detail`,
            data: { details: [row.id] },
          })
          .then(() => {
            this.emailDetails.splice(index, 1);
          })
          .catch((error) => {
            this.logError(error);
          });
      } else {
        this.emailDetails.splice(index, 1);
      }
    },
    pushContactDetail() {
      this.contactDetails.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contactDetails.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },

    getGroupList() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "customer/group-list",
        })
        .then(({ data }) => {
          _this.groupList = data.group_list;
          if (_this.lodash.isEmpty(_this.customer) === true) {
            _this.detail.security_code = data.security_code;
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    editDetails() {
      const _this = this;
      _this.defaultPerson = _this.getDefaultPerson(_this.customer);
      _this.emailDetails = _this.getEmailDetails(_this.customer);
      _this.contactDetails = _this.getPhoneDetails(_this.customer);

      const faxContactDetails = _this.lodash.filter(_this.contactDetails, {
        title: "fax",
      });

      const mainContactDetails = _this.lodash.filter(_this.contactDetails, {
        title: "main",
      });

      if (_this.lodash.isEmpty(mainContactDetails)) {
        _this.contactDetails.push({
          title: "main",
          value: null,
          type: 2,
          id: null,
        });
      }

      if (_this.lodash.isEmpty(faxContactDetails)) {
        _this.contactDetails.push({
          title: "fax",
          value: null,
          type: 2,
          id: null,
        });
      }

      _this.detail.salutation = _this.defaultPerson.title;
      _this.detail.first_name = _this.defaultPerson.first_name;
      _this.detail.last_name = _this.defaultPerson.last_name;
      _this.detail.reference = _this.customer.reference;

      _this.detail.primary = _this.customer.primary;

      _this.detail.company_name = _this.customer.company_name;
      _this.detail.uen = _this.customer.uen;
      _this.detail.display_name = _this.customer.display_name;
      _this.detail.customer_remark = _this.customer.customer_remark;
      _this.detail.entity_id = _this.customer.entity_id;
      _this.detail.group_id = _this.customer.group_id;
      _this.detail.phone = _this.customer.phone;
      _this.detail.website = _this.customer.website;
      _this.detail.unit_number = _this.customer.unit_number;
      _this.detail.address_line_1 = _this.customer.address_line_1;
      _this.detail.address_line_2 = _this.customer.address_line_2;
      _this.detail.company_number = _this.customer.company_number;
      _this.detail.company_fax = _this.customer.company_fax;
      _this.detail.company_email = _this.customer.company_email;
      _this.detail.company_type = _this.customer.company_type;
      _this.detail.postal_code = _this.customer.postal_code;
      _this.detail.country = _this.customer.country;
      _this.detail.security_code = _this.customer.security_code;
      _this.detail.state = _this.customer.state;
      _this.detail.city = _this.customer.city;
      _this.detail.shipcode = _this.customer.shipcode;
      _this.detail.import_ref = _this.customer.import_ref;
      _this.detail.profile_logo = _this.customer.profile_logo;
    },
    getLead() {
      const _this = this;
      let fullname = _this.lead.name ? _this.lead.name.split(" ") : [];
      _this.detail.first_name = fullname[0];
      _this.detail.last_name = fullname[1];
      _this.detail.company_name = _this.lead.company;
      _this.detail.uen = _this.lead.uen;
      _this.detail.display_name = _this.lead.company;
      _this.detail.phone = _this.lead.phone_number;
      _this.detail.website = _this.lead.website;
      _this.detail.postal_code = _this.lead.zip;
      _this.detail.country = _this.lead.country;
      _this.detail.state = _this.lead.state;
      _this.detail.city = _this.lead.city;
      _this.detail.unit_number = _this.lead.unit_no;
      _this.detail.address_line_1 = _this.lead.address_1;
      _this.detail.address_line_2 = _this.lead.address_2;

      _this.emailDetails[0] = new Object({
        title: "main",
        value: _this.lead.email,
        type: 1,
        id: null,
      });

      _this.contactDetails[0] = new Object({
        title: "main",
        value: _this.lead.phone_number,
        type: 2,
        id: null,
      });

      _this.updateDetails();
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.uen = data.uen;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.entityList = getConfig("entity");

    const firstEntity = _this.lodash.find(_this.entityList, { id: 1 });
    if (firstEntity) {
      _this.detail.entity_id = firstEntity.id;
    }

    _this.getGroupList();
    _this.getShipCode();
    if (_this.lodash.isEmpty(_this.customer) === false) {
      _this.editDetails();
    }

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.query.proposal);
    if (_this.proposal > 0) {
      _this.getProposal();
    }
  },
  components: {
    PhoneTextField,
    TextValidateInput,
    /* ContactPersons */
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.detail.profile_logo) === false
        ? this.detail.profile_logo.file.url
        : null;
    },
  },
};
</script>
