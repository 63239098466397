<template>
  <v-layout v-if="getPermission('door:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <div v-if="errors.length" class="iv-custom-field pt-0 width-100">
        <div
          class="alert fade alert-danger"
          role="alert"
          v-bind:class="{ show: errors.length }"
        >
          <div v-for="(error, i) in errors" :key="i" class="alert-text">
            {{ error }}
          </div>
        </div>
      </div>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow service-create-height"
        style="max-height: 90vh; position: relative"
      >
        <v-col md="8" offset-md="2" class="pb-0">
          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Door Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="font-weight-700 pl-2 font-size-16 required"
                >Door Type</label
              >
              <v-autocomplete
                id="door-type"
                dense
                v-model="doorCreate.door_type"
                item-text="title"
                item-color="cyan"
                item-value="id"
                filled
                :items="doorTypeList"
                placeholder="Door Type"
                :readonly="doorId > 0 ? true : false"
                :rules="[
                  validateRules.required(doorCreate.door_type, 'Door Type'),
                ]"
                solo
                flat
                color="cyan"
              />
              <label class="mt-4 font-weight-700 pl-2 font-size-16 required"
                >Name</label
              >
              <TextField
                id="dNamelabel"
                dense
                filled
                placeholder="Name"
                :readonly="doorId > 0 ? true : false"
                solo
                flat
                counter="50"
                v-model.trim="doorCreate.name"
                color="cyan"
                :maxlength="250"
                :rules="[
                  validateRules.required(doorCreate.name, 'Name'),
                  validateRules.minLength(doorCreate.name, 'Name', 1),
                  validateRules.maxLength(doorCreate.name, 'Name', 100),
                ]"
              />

              <label class="mt-4 font-weight-700 pl-2 font-size-16 required"
                >Description</label
              >
              <TextAreaField
                auto-grow
                dense
                filled
                color="cyan"
                v-model.trim="doorCreate.description"
                :rules="[
                  validateRules.required(doorCreate.description, 'Description'),
                  validateRules.minLength(
                    doorCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    doorCreate.description,
                    'Description',
                    500
                  ),
                ]"
                placeholder="Door Description"
                solo
                flat
                row-height="25"
                counter="250"
              />

              <v-layout>
                <v-flex md6>
                  <label class="font-weight-700 pl-2 font-size-16 required"
                    >Door Code/Serial Number
                  </label>
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Door Code/Serial Number"
                    :readonly="doorId > 0 ? true : false"
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model.trim="doorCreate.serial_number"
                    :rules="[
                      validateRules.required(
                        doorCreate.serial_number,
                        'Door Code/Serial Number'
                      ),
                      validateRules.minLength(
                        doorCreate.serial_number,
                        'Door Code/Serial Number',
                        1
                      ),
                      validateRules.maxLength(
                        doorCreate.serial_number,
                        'Door Code/Serial Number',
                        100
                      ),
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex md6>
                  <label class="font-weight-700 pl-2 font-size-16"
                    >Services
                  </label>

                  <v-autocomplete
                    dense
                    class="pl-2"
                    color="cyan"
                    filled
                    id="services"
                    :items="serviceList"
                    v-model.trim="doorCreate.services"
                    label="Services"
                    solo
                    flat
                    item-color="cyan"
                    item-text="name"
                    item-value="id"
                    hide-details
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text font-size-12">
                        (+{{ doorCreate.services.length - 1 }} others)
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Service Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <!--    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Service Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template> -->
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- Sizing Start -->
          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                SIZING Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-row no-gutters>
                <v-col class="pr-0 pt-0 pb-0" cols="6">
                  <label class="font-weight-700 pl-2 font-size-16"
                    >Length</label
                  >
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Length"
                    solo
                    flat
                    class="pr-2 width-100"
                    type="number"
                    min="0"
                    max="9999"
                    v-model.trim.number.lazy="doorCreate.length"
                    v-on:keypress="limitDecimal($event, doorCreate.length)"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-0 pt-0 pb-0" cols="2">
                  <label class="font-weight-700 pl-2 font-size-16">Unit</label>
                  <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2 width-100"
                    v-model.trim="doorCreate.length_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="pr-0 pt-0 pb-0" cols="6">
                  <label class="font-weight-700 pl-2 font-size-16">Width</label>
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Width"
                    solo
                    flat
                    class="pr-2 width-100"
                    type="number"
                    min="0"
                    max="9999"
                    v-model.trim.number.lazy="doorCreate.width"
                    v-on:keypress="limitDecimal($event, doorCreate.width)"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-0 pt-0 pb-0" cols="2">
                  <label class="font-weight-700 pl-2 font-size-16">Unit</label>
                  <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2 width-100"
                    v-model.trim="doorCreate.width_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="pr-0 pt-0 pb-0" cols="6">
                  <label class="font-weight-700 pl-2 font-size-16"
                    >Height</label
                  >
                  <v-text-field
                    dense
                    filled
                    color="cyan"
                    label="Height"
                    solo
                    flat
                    class="pr-2 width-100"
                    type="number"
                    min="0"
                    max="9999"
                    v-model.trim.number.lazy="doorCreate.height"
                    v-on:keypress="limitDecimal($event, doorCreate.height)"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-0 pt-0 pb-0" cols="2">
                  <label class="font-weight-700 pl-2 font-size-16">Unit</label>
                  <v-autocomplete
                    :items="unitList"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    label="Unit"
                    solo
                    flat
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="UomData"
                    item-text="text"
                    item-value="text"
                    class="pl-2 width-100"
                    v-model.trim="doorCreate.height_unit"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Unit(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                DOOR Images
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div v-if="getPermission('attachment:create')">
                <label class="font-weight-700 font-size-16 width-100"
                  >Images
                </label>
                <div>
                  <FileTemplate
                    allowUpload
                    isMinDisplay
                    :attachments="doorCreate.product_images"
                    v-on:file:updated="updateImages"
                    :file-type="true"
                    acceptFile="image/png, image/jpeg, image/jpg"
                  ></FileTemplate>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </perfect-scrollbar>
    </v-col>

    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      :exist-unit="doorCreate.length_unit"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";

import {
  QUERY,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
//import { map, compact } from "lodash";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    duplicateId: {
      type: Number,
      default: null,
    },
    doorId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    doorCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  data() {
    return {
      manageUnitDialog: false,

      doorTypeList: [],

      timeoutLimit: 500,
      timeout: null,

      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      serviceUnitDialog: false,
      unitList: new Array(),
      serviceList: new Array(),
      barcode: null,

      warrantyActive: false,
      doorCreate: {
        name: null,
        barcode: null,
        description: null,
        product_type: "door",
        length: null,

        width: null,
        height: null,
        weight: null,

        length_unit: null,
        width_unit: null,
        height_unit: null,

        images: [],
        product_images: [],
      },
    };
  },
  components: {
    FileTemplate,
    ManageUnit,
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    doorCreateData: {
      deep: true,
      //immediate: true,
      handler(param) {
        this.doorCreate = param;
        // console.log({param})
      },
    },
    doorCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveDoor", _this.doorCreate);
        }, _this.timeoutLimit);
        if (_this.duplicateId) {
          this.doorCreate.barcode = this.barcode;
        }
        //this.doorCreate.barcode = this.barcode;
      },
    },
    "doorCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },

    updateImages(param) {
      this.doorCreate.images = param;
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.doorCreate.product_type },
        })
        .then((response) => {
          if (
            !_this.doorId &&
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode
          ) {
            _this.barcode = response.data.barcode;
            _this.doorCreate.barcode = response.data.barcode;
          }
          _this.serviceList = response.data.services;
          _this.doorTypeList = response.data.door_types;

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>
